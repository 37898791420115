var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"overflow-y-auto",staticStyle:{"max-height":"100vh"},attrs:{"elevation":"0","flat":""}},[_c('v-app-bar',{attrs:{"flat":"","dark":"","color":"primary"}},[_c('v-toolbar-title',[_vm._v("Assigner à un collaborateur")]),_c('v-spacer')],1),_c('v-card-text',[_c('v-form',{ref:"ticketCollabForm",staticClass:"px-3"},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"11"}},[_c('v-select',{attrs:{"label":"Métiers","items":_vm.metiers,"item-text":"name","item-value":"id"},model:{value:(_vm.metier),callback:function ($$v) {_vm.metier=$$v},expression:"metier"}})],1),_c('v-col',{attrs:{"cols":"1"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mt-4 ml-2",attrs:{"color":"red","large":""},on:{"click":function($event){_vm.metier = null}}},'v-icon',attrs,false),on),[_vm._v(" mdi-close ")])]}}])},[_c('span',[_vm._v("Retirer le métier")])])],1)],1),_c('v-select',{attrs:{"label":"Collaborateur","items":_vm.filtered_collaborateurs,"item-text":"fullname","rules":[_vm.notNullNorEmpty],"item-value":"id"},model:{value:(_vm.prestataire),callback:function ($$v) {_vm.prestataire=$$v},expression:"prestataire"}}),_c('v-btn',{staticClass:"primary--text",attrs:{"text":"","to":"/admin?tab=user&new=true"}},[_c('v-icon',[_vm._v("mdi-plus")]),_vm._v(" Ajouter un collaborateur")],1),_c('v-row',[_c('v-col',{attrs:{"cols":"8"}},[_c('v-menu',{attrs:{"max-width":"100%","close-on-click":true,"close-on-content-click":false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"rules":[_vm.notNullNorEmpty],"label":"Date du début","value":_vm.getFormattedDate(_vm.date_debut)}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.dateDebutMenu),callback:function ($$v) {_vm.dateDebutMenu=$$v},expression:"dateDebutMenu"}},[_c('v-card',[_c('v-date-picker',{on:{"change":_vm.updateDates},model:{value:(_vm.date_debut),callback:function ($$v) {_vm.date_debut=$$v},expression:"date_debut"}}),_c('v-card-actions',[_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.dateDebutMenu = false}}},[_vm._v("Fermer")])],1)],1)],1)],1),_c('v-col',{attrs:{"cols":"4"}},[_c('v-menu',{attrs:{"max-width":"100%","close-on-click":true,"close-on-content-click":false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Heure du début","value":_vm.heure_date_debut}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.heureDebutMenu),callback:function ($$v) {_vm.heureDebutMenu=$$v},expression:"heureDebutMenu"}},[_c('v-card',[_c('v-time-picker',{attrs:{"format":"24hr"},model:{value:(_vm.heure_date_debut),callback:function ($$v) {_vm.heure_date_debut=$$v},expression:"heure_date_debut"}}),_c('v-card-actions',[_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.heureDebutMenu = false}}},[_vm._v("Fermer")])],1)],1)],1)],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"8"}},[_c('v-menu',{attrs:{"max-width":"100%","close-on-click":true,"close-on-content-click":false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Date du fin","value":_vm.getFormattedDate(_vm.date_fin)}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.dateFinMenu),callback:function ($$v) {_vm.dateFinMenu=$$v},expression:"dateFinMenu"}},[_c('v-card',[_c('v-date-picker',{model:{value:(_vm.date_fin),callback:function ($$v) {_vm.date_fin=$$v},expression:"date_fin"}}),_c('v-card-actions',[_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.dateFinMenu = false}}},[_vm._v("Fermer")])],1)],1)],1)],1),_c('v-col',{attrs:{"cols":"4"}},[_c('v-menu',{attrs:{"max-width":"100%","close-on-click":true,"close-on-content-click":false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Heure de fin","value":_vm.heure_date_fin}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.heureFinMenu),callback:function ($$v) {_vm.heureFinMenu=$$v},expression:"heureFinMenu"}},[_c('v-card',[_c('v-time-picker',{attrs:{"format":"24hr"},model:{value:(_vm.heure_date_fin),callback:function ($$v) {_vm.heure_date_fin=$$v},expression:"heure_date_fin"}}),_c('v-card-actions',[_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.heureFinMenu = false}}},[_vm._v("Fermer")])],1)],1)],1)],1)],1)],1)],1),_c('v-divider'),_c('v-card-actions',[_c('v-spacer'),(_vm.validating)?_c('v-progress-circular',{staticClass:"ml-2",attrs:{"indeterminate":"","color":"primary"}}):_vm._e(),_c('v-btn',{attrs:{"color":"primary","disabled":_vm.validating,"text":""},on:{"click":_vm.assignTicket}},[_vm._v(" Assigner le ticket ")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }