<template>
  <div>
    <v-card class="mx-0 pa-2" elevation="0" v-if="selected_ticket !== null && loaded_ticket" outlined>
      <v-row justify="space-between">
        <v-col>
          <h3 class="primary--text text--darken-2">{{ selected_ticket.objet }}
            <span class="orange--text font-italic">#{{ selected_ticket.id }}</span></h3>
        </v-col>
        <v-col>
          <v-btn text v-if="isResponsableOrAdmin || hasPermission('change_ticket')" @click="editTicket(selected_ticket)" color="primary">
            <v-icon>mdi-pencil</v-icon>
            Modifier
          </v-btn>
          <v-dialog v-model="deleteDialog" v-if="isResponsableOrAdmin || hasPermission('delete_ticket')" width="500">
            <template v-slot:activator="{on, attrs}">
              <v-btn text v-on="on" v-bind="attrs" color="error">
                <v-icon>mdi-trash-can-outline</v-icon>
                Supprimer
              </v-btn>
            </template>
            <v-card>
              <v-card-title class="headline red lighten-1 white--text">
                Supprimer le ticket
              </v-card-title>
              <v-card-text class="body-1 mt-2">
                Confirmer la suppression du ticket ?
              </v-card-text>
              <v-card-actions>
                <v-btn @click="deleteTicket(selected_ticket)" text color="red">Supprimer le ticket</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-col>
      </v-row>
      <v-row no-gutters>
        <v-col cols="12">
                <span :class="getStatusColor(selected_ticket.state)">{{
                    getStatusDisplay(selected_ticket.state)
                  }}</span>
        </v-col>
        <v-col cols="12">
          <p class="grey--text">Créé le {{ formatDate(selected_ticket.date_creation) }}</p>
        </v-col>
        <v-col cols="12">
          <p>{{ selected_ticket.description }}</p>
        </v-col>
        <v-col cols="12">
          <v-divider class="mx-1" light></v-divider>
        </v-col>

        <v-col cols="6" xl="3" v-if="selected_ticket.asset !== null">
          <detail-card clickable
                       @click.native="selected_ticket.asset !== null ? $router.push('/assets/' + selected_ticket.asset.id) : null"
                       icon="mdi-wrench" name="Équipement"
                       :information="selected_ticket.asset !== null ? selected_ticket.asset.name : 'Non défini'"/>
        </v-col>
        <v-col cols="6" xl="3" v-if="selected_ticket.created_by !== null">
          <detail-card
                       icon="mdi-account" name="Demandé par"
                       :information="selected_ticket.created_by !== null ? selected_ticket.created_by.fullname : 'Non défini'"/>
        </v-col>
        <v-col cols="6" xl="3" v-if="selected_ticket.parent !== null">
          <detail-card
                       icon="mdi-ticket" @click.native="goToParent" name="Ticket parent"
                       :information="'Ticket #' + selected_ticket.id + ' : ' + selected_ticket.parent.objet"/>
        </v-col>
        <v-col cols="6" xl="3">
          <v-list-item two-line>
            <v-list-item-icon>
              <v-icon class="pt-1 pb-0">mdi-priority-high</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>
                Priorité
              </v-list-item-title>
              <v-list-item-subtitle>{{ priority_labels[selected_ticket.priority] }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-col>
        <v-col cols="12" v-if="selected_ticket.asset !== null">
          <v-list-item two-line>
            <v-list-item-icon class="align-center justify-center">
              <v-icon>mdi-map-marker</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>
                Site
              </v-list-item-title>
              <v-list-item-subtitle v-if="selected_ticket.asset.site !== null">
                <span v-if="selected_ticket.asset.site.name !== null">{{ selected_ticket.asset.site.name }}</span>
                <span v-if="selected_ticket.asset.site.parent !== null">, {{ selected_ticket.asset.site.parent }}</span>
                <span v-if="selected_ticket.asset.site.top !== null">, {{ selected_ticket.asset.site.top }}</span>
              </v-list-item-subtitle>
              <v-list-item-subtitle v-else>
                <span v-if="selected_ticket.asset.site === null" class="font-italic grey--text">Non défini</span>
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-col>
        <v-col cols="12" v-if="selected_ticket.tickets.length > 0">
          <v-row no-gutters>
            <v-col cols="12">
              <h4>Sous-tickets</h4>
            </v-col>
            <v-col lg="4" cols="6" v-for="sous_ticket of selected_ticket.tickets" :key="sous_ticket.id">
              <detail-card
                       icon="mdi-ticket" @click.native="goToTicket(sous_ticket)" :information="STATUS_LABELS[sous_ticket.state]"
                       :name="'Ticket #' + sous_ticket.id + ' : ' + sous_ticket.objet"/>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="10">
          <p class="body-1 mt-2"><strong>{{ nombre_pieces_jointes }} pièce(s) jointe(s) </strong></p>
        </v-col>
        <v-col cols="2">
          <v-dialog v-model="attachmentDialog" width="500">
            <template v-slot:activator="{ on, attrs }">
              <v-btn v-on="on" v-bind="attrs" elevation="2" color="primary" fab>
                <v-icon>mdi-plus</v-icon>
              </v-btn>
            </template>
            <PieceJointeForm @close-dialog="attachmentDialog = false;" type="ticket"/>
          </v-dialog>
        </v-col>
        <v-col cols="6" xl="3" class="mb-2 pa-0 mini-card" v-for="pieceJointe in selected_ticket.pieces_jointes"
               :key="pieceJointe.id">
          <v-row>
            <v-col cols="10" class="pa-0">
              <v-list-item two-line :href="pieceJointe.file" target="_blank">
                <v-list-item-icon class="d-flex" v-if="hasThumbnail(pieceJointe)">
                  <v-img max-width="50" class="justify-center align-center" :src="pieceJointe.thumbnail"></v-img>

                </v-list-item-icon>
                <v-list-item-icon v-else>
                  <v-icon>mdi-file</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>{{ pieceJointe.label }}</v-list-item-title>
                  <v-list-item-subtitle>{{ getFilename(pieceJointe.path) }}</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-col>
            <v-col class="align-center justify-center pa-0" cols="2">
              <v-icon @click="deleteAttachment(pieceJointe)">mdi-trash-can-outline</v-icon>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-row v-if="selected_ticket.intervention === null && selected_ticket.asset === null" no-gutters
             class="py-4 ml-3">
        <v-col cols="12"><span class="red--text">Veuillez associer un équipement à ce ticket.</span></v-col>
        <v-col>
          <v-btn class="mx-2" v-if="isResponsableOrAdmin || hasPermission('change_ticket')" @click="editTicket(selected_ticket)" color="primary">Associer un équipement</v-btn>
          <v-btn class="mx-2" to="/assets?new=true" color="primary">Créer un nouvel équipement</v-btn>
        </v-col>
      </v-row>
      <v-row v-else-if="selected_ticket.intervention === null" no-gutters class="py-4 ml-3">
        <v-col>
          <v-btn v-if="isResponsableOrAdmin || hasPermission('can_assign')" color="primary" @click="assignCollaborateur">
            Assigner à un collaborateur
          </v-btn>
        </v-col>
        <v-col>
          <v-btn v-if="isResponsableOrAdmin || hasPermission('can_assign')" color="primary" @click="choosePrestataire">
            Proposer à un prestataire
          </v-btn>
        </v-col>
        <v-col>
          <v-btn v-if="isCollabOrAdmin || hasPermission('can_self_assign')" color="primary" @click="assignToCurrentUser">
            S'assigner ce ticket
          </v-btn>
        </v-col>
      </v-row>
      <v-row no-gutters class="py-4 ml-3" v-else-if="intervention !== null">
        <v-col cols="12">
          <v-row>
            <p class="font-weight-bold mb-0 pa-1">Intervention planifiée</p>
            <v-spacer></v-spacer>
            <v-btn color="primary" v-if="intervention !== null" :to="'/interventions/' + intervention.id" text>Voir
              l'intervention
            </v-btn>
          </v-row>
          <v-row
              v-if="!intervention.validation && intervention.date_intervention !== null && intervention.prestataire !== null">
            <p class="orange--text">Cette intervention est en attente de validation prestataire.</p>
          </v-row>
        </v-col>

        <v-col cols="12" sm="4">
          <detail-card icon="mdi-account" name="Affectée à"
                       :information="intervention.prestataire !== null ? intervention.prestataire.fullname : 'Non associé'"/>
        </v-col>
        <v-col cols="12" sm="4">
          <detail-card icon="mdi-calendar" name="Planifiée pour le"
                       :information="intervention.date_intervention != null ? formatDate(intervention.date_intervention) : 'Non planifié'"/>
        </v-col>
        <v-col cols="12" sm="4">
          <detail-card icon="mdi-calendar" name="Réalisée pour le"
                       :information="intervention.date_fin_intervention != null ? formatDate(intervention.date_fin_intervention) : 'Non planifié'"/>
        </v-col>
        <v-col cols="12" sm="4">
          <detail-card icon="mdi-calendar" name="Réalisée le"
                       :information="intervention.date_realisation != null ? formatDate(intervention.date_realisation) : 'Non planifié'"/>
        </v-col>
      </v-row>
      <v-divider></v-divider>
      <v-row no-gutters class="py-2 ml-3" v-if="isResponsableOrAdmin">
        <v-col class="mt-2" cols="4">
          <v-btn @click="changeState('closed')" class="blue darken-4" v-if="selected_ticket.state !== 'closed'"
                 light>
            <span class="white--text"><v-icon left>mdi-close</v-icon>Clôturer le ticket</span>
          </v-btn>
        </v-col>
        <v-col class="mt-2" cols="4" v-if="selected_ticket.state === 'unassigned'">
          <v-btn @click="changeState('paused')" class="brown darken-2"
                 light>
            <span class="white--text"><v-icon left>mdi-pause</v-icon>Mettre en suspens</span>
          </v-btn>
        </v-col>
        <v-col class="mt-2" cols="4" v-if="selected_ticket.state === 'paused'">
          <v-btn @click="changeState('unassigned')" class="primary"
                 light>
            <span class="white--text"><v-icon left>mdi-play</v-icon>Réactiver le ticket</span>
          </v-btn>
        </v-col>
        <v-col class="mt-2" cols="4" v-if="selected_ticket.intervention !== null">
          <v-btn @click="reassignTicket" class="red lighten-2"><span class="white--text"><v-icon>mdi-account-remove</v-icon> Réassigner le ticket</span>
          </v-btn>
        </v-col>
        <v-col class="mt-2" v-if="selected_ticket.state === 'closed'"  cols="4">
          <v-btn @click="changeState('waiting')" class="green darken-1"
                 light>
            <span class="white--text"><v-icon left>mdi-hammer</v-icon>Réouvrir le ticket</span>
          </v-btn>
        </v-col>
        <v-col class="mt-2" v-if="selected_ticket.state !== 'closed' && selected_ticket.parent === null"  cols="4">
          <v-btn @click="createChildTicket" class="green darken-1"
                 light>
            <span class="white--text"><v-icon left>mdi-hammer</v-icon>Créer un sous-ticket</span>
          </v-btn>
        </v-col>
      </v-row>
      <v-divider></v-divider>
      <v-tabs icons-and-text v-model="tab">
        <v-tabs-slider></v-tabs-slider>
        <v-tab>
          Commentaires
          <v-icon>mdi-message</v-icon>
        </v-tab>
      </v-tabs>

      <v-tabs-items v-model="tab">
        <v-tab-item>
          <Commentaires :ticket="selected_ticket" :key="selected_ticket.id"/>
        </v-tab-item>
      </v-tabs-items>
    </v-card>
    <v-card v-else-if="loaded_ticket === false">
      <v-row align="center" justify="center">
        <v-col cols="1">
          <v-progress-circular indeterminate color="primary"></v-progress-circular>
        </v-col>
      </v-row>
    </v-card>
  </div>
  
</template>
<script>
import InterventionAssignementForm from "@/components/interventions/InterventionAssignementForm";
import InterventionPropositionForm from "@/components/interventions/InterventionPropositionForm";
import DetailCard from "@/components/base/DetailCard";
import DateUtilMixin from "@/mixins/util/DateUtilMixin";
import PieceJointeForm from "@/components/tickets/PieceJointeForm";
import {AuthMixin} from "@/mixins/util/AuthMixin";
import Commentaires from "@/components/tickets/Commentaires";
import TicketMixin from "@/mixins/tickets/TicketMixin";
import SelfAssignmentForm from "@/components/interventions/SelfAssignmentForm";
import PieceJointeMixin from "@/mixins/util/PieceJointeMixin";
import {DrawerMixin} from "@/mixins/util/DrawerMixin";
import NewTicketSidebar from "@/components/tickets/NewTicketSidebar";
import {STATUS_LABELS} from "@/constants/tickets";

export default {
  name: 'TicketView',
  components: {
    Commentaires, PieceJointeForm, DetailCard},
  mixins: [DateUtilMixin, AuthMixin, TicketMixin, PieceJointeMixin, DrawerMixin],
  async mounted() {
    if (this.$route.params?.id !== undefined) {
      await this.$store.dispatch("tickets/getTicketById", this.$route.params?.id);
    }
    this.loaded_ticket = true;
  },
  data: function () {
    let ticket = this.$store.getters["tickets/getSelectedTicket"];
    return {
      STATUS_LABELS,
      assignmentDrawer: false,
      propositionDrawer: false,
      attachmentDialog: false,
      selfDrawer: false,
      tab: null,
      deleteDialog: false,
      cout: ticket?.intervention != null ? ticket.intervention.cout_achat : null,
      note: ticket?.intervention != null ? ticket.intervention.note : null,
      loaded_ticket: false,
    }
  },
  methods: {
    editTicket() {
      this.$emit("edit-ticket");
    },
    assignCollaborateur() {
      this.openDrawerDialog(InterventionAssignementForm, {});
    },
    choosePrestataire() {
      this.openDrawerDialog(InterventionPropositionForm, {});
    },
    assignToCurrentUser() {
      this.openDrawerDialog(SelfAssignmentForm, {});
    },
    createChildTicket() {
      let properties = {
        operation: "create",
        parentTicket: this.selected_ticket.id,
        key: -1
      };
      this.openDrawerDialog(NewTicketSidebar, properties);
    },
    changeState(state) {
      let updatedTicket = {id: this.selected_ticket.id, state};
      let that = this;
      if (this.cout !== null && state === "finished") {
        this.setInterventionCost().then(function () {
          that.$store.dispatch("tickets/patchTicket", updatedTicket);
        })
      } else {
        this.$store.dispatch("tickets/patchTicket", updatedTicket);
      }
    },
    setInterventionCost() {
      let intervention = {
        id: this.intervention.id,
        cout_achat: this.cout,
      }
      return this.$store.dispatch("interventions/patchIntervention", intervention);
    },
    async deleteAttachment(attachment) {
      let result = await this.$store.dispatch("attachments/deleteTicketAttachment", attachment);
      if (result === "success") {
        this.$store.dispatch("messages/setAnnounce", {"type": "success", "message": "Document supprimé."});
      } else {
        this.$store.dispatch("messages/setAnnounce", {
          "type": "warning",
          "message": "Une erreur est survenue. Le document n'a peut être pas été correctement supprimé."
        });
      }
    },
    async reassignTicket() {
      try {
        await this.$store.dispatch("interventions/deleteIntervention", this.selected_ticket.intervention);
        await this.$store.dispatch("tickets/patchTicket", {id: this.selected_ticket.id, state: "unassigned"});
        this.$store.dispatch("tickets/getTicketById", this.selected_ticket.id);
        this.$store.dispatch("messages/setAnnounce", {
          "type": "success",
          "message": "Vous pouvez désormais réassigner ce ticket."
        });
      } catch (e) {
        this.$store.dispatch("messages/setAnnounce", {
          "type": "warning",
          "message": "Une erreur est survenue."
        });
      }
    },
    async setCostAndRating() {
      try {
        await this.$store.dispatch("interventions/patchIntervention", {
          id: this.intervention.id,
          cout_achat: this.cout,
          note: this.note,
        });
        await this.$store.dispatch("tickets/getTicketById", this.selected_ticket.id)
        await this.$store.dispatch("messages/setAnnounce", {
          "type": "success",
          "message": "Prix et note affecté."
        });
      } catch (e) {
        await this.$store.dispatch("messages/setAnnounce", {
          "type": "warning",
          "message": "Une erreur est survenue."
        });
      }
    },
    async confirmIntervention(message, isWaiting) {
      let commentaire = {
        ticket: this.selected_ticket.id,
        attachment: null,
        created_by: this.logged_user != null ? this.logged_user.id : null,
        message,
      }
      this.loading = true;
      await this.$store.dispatch("commentaires/postCommentaire", {ticket: this.selected_ticket, commentaire});
      await this.$store.dispatch("interventions/patchIntervention", {
        id: this.intervention.id,
        date_realisation: isWaiting ? null : this.getNow(),
        waiting: isWaiting
      })
      this.changeState('processed');
      this.loading = false;
    },
    async deleteTicket(ticket) {
      await this.$store.dispatch("tickets/deleteTicket", ticket);
      this.deleteDialog = false;
    },
    async goToParent() {
      await this.$store.dispatch("tickets/getTicketById", this.selected_ticket.parent.id);
      const path = `/tickets/${this.selected_ticket.id}`;
      if (this.$route.path !== path) this.$router.push(path);
    },
    async goToTicket(ticket) {
      await this.$store.dispatch("tickets/getTicketById", ticket.id);
      const path = `/tickets/${ticket.id}`;
      if (this.$route.path !== path) this.$router.push(path);
    },
    getFilename(path) {
      let elements = path.split("/")
      return elements[elements.length - 1]
    },
  },

  computed: {
    selected_ticket() {
      return this.$store.getters["tickets/getSelectedTicket"];
    },
    nombre_pieces_jointes() {
      return this.selected_ticket !== null ? this.selected_ticket.pieces_jointes.length : 0;
    },
    intervention() {
      return this.selected_ticket.intervention;
    },
    canNoteIntervention() {
      return this.logged_user.id !== this.intervention?.prestataire?.id
          && ["Responsable technique", "Administrateur", "Chef de rayon", "Chef de département"].indexOf(this.logged_user.group) !== -1;
    },
  }
}
</script>
<style scoped>
.mini-card {
  border: 1px solid #a59999;
}
</style>