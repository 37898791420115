<template>
  <v-card elevation="0" flat style="max-height: 100vh" class="overflow-y-auto">
    <v-app-bar flat dark color="primary">
      <v-toolbar-title>Proposer à un prestataire</v-toolbar-title>
      <v-spacer></v-spacer>
    </v-app-bar>
    <v-card-text>
      <v-form ref="ticketPrestataireForm" class="px-3">
        <v-row no-gutters>
          <v-col cols="11">
            <v-select label="Métiers"
                      :items="metiers"
                      v-model="metier"
                      item-text="name"
                      item-value="id"></v-select>
          </v-col>
          <v-col cols="1">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-icon @click="metier = null" class="mt-4 ml-2" color="red" large v-on="on" v-bind="attrs">
                  mdi-close
                </v-icon>
              </template>
              <span>Retirer le métier</span>
            </v-tooltip>
          </v-col>
        </v-row>
        <v-select label="Prestataire"
                  :items="filtered_prestataires"
                  item-text="fullname"
                  v-model="prestataire"
                  :rules="[notNullNorEmpty]"
                  item-value="id"></v-select>
        <v-btn text color="primary" :to="createPrestataireLink">
          <v-icon>mdi-plus</v-icon>
          Créer un prestataire
        </v-btn>
        <v-checkbox v-model="manualDirectAssign" label="Assigner le ticket sans attendre de réponse"></v-checkbox>
        <v-menu v-if="manualDirectAssign" max-width="100%" v-model="dateDebutMenu" :close-on-click="true" :close-on-content-click="false">
          <template v-slot:activator="{on, attrs}">
            <v-text-field :rules="[notNullNorEmpty, mandatoryDateIfDirectAssign]" v-on="on" v-bind="attrs" label="Date de l'intervention"
                          :value="getFormattedDate(date_debut)"></v-text-field>
          </template>
          <v-card>
            <v-date-picker v-model="date_debut"></v-date-picker>
            <v-card-actions>
              <v-btn text color="primary" @click="dateDebutMenu = false">Fermer</v-btn>
            </v-card-actions>
          </v-card>
        </v-menu>
      </v-form>
    </v-card-text>

    <v-divider></v-divider>


    <v-card-actions>
      <v-spacer></v-spacer>
      <v-progress-circular v-if="updating" indeterminate color="primary"/>
      <v-btn :color="updating ? 'grey' : 'primary'" :disabled="updating" text @click="assignTicket">
        Assigner le ticket
      </v-btn>
    </v-card-actions>
  </v-card>
</template>
<script>
import {mapGetters} from "vuex";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import ValidationRulesMixin from "@/mixins/util/ValidationRulesMixin";
import DateUtilMixin from "@/mixins/util/DateUtilMixin";

dayjs.extend(customParseFormat)

export default {
  name: 'InterventionPropositionForm',
  mixins: [ValidationRulesMixin, DateUtilMixin],
  data() {
    return {
      "metier": null,
      "prestataire": null,
      "date_debut": null,
      "dateDebutMenu": false,
      "manualDirectAssign": false,
      updating: false,
    }
  },
  mounted() {
    if (this.metiers.length === 0) {
      this.$store.dispatch("categories/fetchCategories");
    }
    if (this.users.length === 0) {
      this.$store.dispatch("profile/fetchProfiles");
    }
  },
  computed: {
    ...mapGetters("categories", [
      "metiers",
    ]),
    ...mapGetters("profile", [
      "prestataires",
      "users",
      "collaborateurs",
    ]),
    filtered_prestataires() {
      return this.prestataires.filter(item => {
        if (this.metier !== null) {
          let metiers_ids = item.profile.metiers.map(metier => metier.id);
          return metiers_ids.includes(this.metier);
        } else {
          return true;
        }
      })
    },
    createPrestataireLink() {
      return `/intervenants?new=true&back=${encodeURIComponent(this.$route.fullPath)}`;
    }
  },
  methods: {
    async assignTicket() {
      if (this.$refs.ticketPrestataireForm.validate()) {
        this.updating = true;
        let ticketId = this.$store.getters["tickets/getSelectedTicket"].id;
        let intervention = this.manualDirectAssign ? {
          "date_intervention": this.getCleanDate(this.date_debut, "10:00"),
          "date_fin_intervention": this.getCleanDate(this.date_debut, "11:00"),
          "validation": true,
          "cout_achat": null,
          "ticket": ticketId,
          "prestataire": this.prestataire,
          "categorie": this.metier
        } : {
          "date_intervention": null,
          "date_fin_intervention": null,
          "validation": false,
          "cout_achat": null,
          "ticket": ticketId,
          "prestataire": this.prestataire,
          "categorie": this.metier
        }
        let store = this.$store;
        try {
          await this.$store.dispatch("interventions/postIntervention", intervention);
          await this.$store.dispatch("tickets/patchTicket", {"id": ticketId, "state": this.manualDirectAssign ? "ready" : "waiting"});
          store.dispatch("messages/setAnnounce", {"type": "success", "message": "Intervention créée !"});
        } catch (e) {
          console.error(e);
        }
        this.updating = true;
        this.$store.dispatch("drawer/closeDialog");
        this.resetFields();
      }
    },
    resetFields() {
      this.metier = null;
      this.prestataire = null;
    },
    mandatoryDateIfDirectAssign(value) {
      return this.manualDirectAssign ? value !== null || "Veuillez choisir une date d'intervention" : true;
    }

  }
}
</script>
